
const t1 = new TimelineMax({paused:true});
t1.to(".one", 0.2,{
  y:6,
  rotation:45,
  ease:Expo.easeInOut
});
t1.to(".two", 0.5,{
  x:0,
  y:-6,
  rotation:-45,
  ease:Expo.easeInOut,
  delay:-0.2
});

t1.to(".menu",.5,{
  top:"0%",
  ease:Power3.easeOut,
});

t1.staggerFrom(".menu ul li", .9,{
  x:-200,
  opacity:0,
  ease:Expo.easeInOut
},0.1
);
t1.reverse();

document.querySelector('.toggle-btn').addEventListener("click", function(){
t1.reversed(!t1.reversed()); 
});

document.querySelector('#feecharBtn').addEventListener("click", function(){
t1.reversed(!t1.reversed()); 
});

const imgLogo = document.getElementById("logoDoceMel");
TweenMax.from(imgLogo, 2, {
  y:-20,
  ease: Power1.easeOut,
  
});

const imgEntrada = document.getElementById("imgEntrada");
TweenMax.from(imgEntrada, 1, {
  y:-40,
  ease: Expo.easeOut,
  scale:2,
})
